// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/logo.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ppcontainer{position:relative;padding:10px 0 10px 0;background:#fff;width:100%}.ppcontainer .logo{width:20%;height:40px;max-width:130px;margin:20px auto 0 auto;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain}.ppcontainer .header{width:calc(100% - 60px);height:auto;margin:0 30px}.ppcontainer .v-lazy-image{filter:blur(10px);transition:filter .7s}.ppcontainer .v-lazy-image-loaded{filter:blur(0)}.ppcontainer .title{text-transform:uppercase;color:#4f5c68;font-size:1.8em;font-weight:800;line-height:1em;text-align:center;width:100%;display:inline-block;margin:10px auto 10px auto}@media screen and (min-width:768px){.ppcontainer .title{font-size:3.2em}}@media screen and (min-width:1024px){.ppcontainer .title{font-size:5em}}.ppcontainer .text{padding:0 10px 20px 10px;font-size:1em;color:#4c5d6a;text-align:justify}.ppcontainer .text.noheader{margin:0}.ppcontainer .text.notitle{margin:20px 0 0 0}.ppcontainer .text span{color:#688194;text-decoration:underline}@media screen and (min-width:768px){.ppcontainer .text{font-size:1.8em;padding:0 10px 20px 10px}}@media screen and (min-width:1024px){.ppcontainer .text{font-size:2.1em}}.absolutefooter .tcf20container{height:100%}", ""]);
// Exports
module.exports = exports;
