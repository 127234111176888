<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        奥飞特七网络科技有限公司 OUTFIT7 NETWORK TECHNOLOGY 最终用户许可协议
      </h1>
      <div class="text notitle">
        <p>
          本最终用户许可协议（&ldquo;EULA&rdquo;或&ldquo;协议&rdquo;）的条款针对您使用所安装的奥飞特七网络科技有限公司 Outfit7
          Network Technology 分发的应用程序以及在任何方面替代或补充该应用程序的任何和所有相关更新和升级（&ldquo;应用程序&rdquo;或&ldquo;应用&rdquo;），规范您与奥飞特七网络科技有限公司
          Outfit7 Network Technology Co., Ltd 的关系，公司注册地址为中华人民共和国浙江省绍兴市上虞区曹娥街道 e 游小镇门户客厅 2
          号楼 B 区 4 层 401 号 2 号工位（连同其子公司和附属公司，在此统称为&ldquo;奥飞特七网络科技有限公司 Outfit7 Network
          Technology&rdquo;或&ldquo;我们&rdquo;），如其中任何更新或升级附有单独许可，则以该单独许可为准。
        </p>

        <p>
          奥飞特七网络科技有限公司 Outfit7 Network Technology 对您的信息（包括个人信息）的收集、使用和披露受奥飞特七网络科技有限公司
          Outfit7 Network Technology 《隐私政策》约束，请访问 <ExternalLink
            url="https://talkingtomandfriends.com/customs-privacy/o7-networktech-privacypolicy/zh-simp"
            text="https://talkingtomandfriends.com/customs-privacy/o7-networktech-privacypolicy/zh-simp"
          />（iOS
          设备）和 <ExternalLink
            url="https://talkingtomandfriends.com/customs-privacy/networktech-android-privacypolicy/zh-simp"
            text="https://talkingtomandfriends.com/customs-privacy/networktech-android-privacypolicy/zh-simp"
          />（Android
          设备）查看《隐私政策》。另外，我们的《隐私政策》中还介绍了收集14岁以下儿童的个人信息的后果及特定地区的儿童保护条款。
        </p>

        <p>
          应用程序的使用还受奥飞特七网络科技有限公司 Outfit7 Network Technology《视频政策》约束，该《视频政策》构成本协议的一部分，可访问
          <ExternalLink
            url="https://talkingtomandfriends.com/customs-privacy/o7-networktech-video-policy/zh-simp"
            text="https://talkingtomandfriends.com/customs-privacy/o7-networktech-video-policy/zh-simp"
          />
          查看。
        </p>

        <p>
          无论是通过操作系统驱动的设备上运行的任意在线软件应用程序商店（例如 Apple App Store）下载奥飞特七网络科技有限公司
          Outfit7 Network Technology 应用程序，还是在线运行该应用程序（可用时），均表示您确认自己已经阅读并接受该在线市场、平台或操作系统的服务条款（&ldquo;特殊条款&rdquo;）。
        </p>

        <p>
          安装或使用本应用程序，即表示您同意并愿意受本 EULA 条款的约束。若不同意本 EULA 条款，或不希望受本
          EULA 条款约束，则请勿安装、使用或访问本应用程序。如本 EULA 中的条款与任何特殊条款存在冲突，应始终以本 EULA 条款为准。
        </p>

        <p>请参阅应用商店的说明和/或应用程序的&ldquo;玩法&rdquo;部分，了解各应用程序的主要特点，其具体位置视情况而定。</p>

        <h2>1. 许可</h2>
        <h2>1.1 有限的许可授予</h2>

        <p>
          根据本 EULA 的规定，通过购买或使用本应用程序，您即获得由奥飞特七网络科技有限公司 Outfit7
          Network Technology 授予的应用程序安装和使用许可，该许可为可撤销的有限、非独家、不可转授权、不可转让的个人许可，仅供您个人使用，不得用于商业用途。此应用程序授权给您使用，您在此确认应用程序中无任何所有权转让或分配予您，并且建立本
          EULA 并非售卖应用程序中的任何权利。您在此获得的权利受制于您对本 EULA 的履行情况，且您同意不将应用程序用于任何其他用途。禁止任何商业用途。
        </p>

        <h2>1.2 许可限制</h2>
        <p>严禁将应用程序用于任何违反这些许可限制的用途，否则可能导致您的有限许可立即被撤销，且您可能将面临违法责任。</p>
        <p>在未事先收到奥飞特七网络科技有限公司 Outfit7 Network Technology 书面授权的情况下，您同意不会进行以下行为：</p>

        <ul>
          <li>使用欺诈方法、自动软件、机器人、非法侵入或其他任何未授权的软件，篡改或干扰应用程序和/或作为应用程序一部分的任何文件；</li>
          <li>商业性地利用奥飞特七网络科技有限公司 Outfit7 Network Technology 应用程序、其游戏玩法、角色、设计、 2 维或 3 维模型、美术作品或该应用程序的任何其他部分；</li>
          <li>创建应用程序的衍生作品；</li>
          <li>使用非本应用程序提供的技术/方法或超出奥飞特七网络科技有限公司 Outfit7 Network Technology 明确授权的方法访问或使用该应用程序；</li>
          <li>通过网络上提供的方法，制作可供多用户使用的本应用程序的副本；</li>
          <li>注册含有奥飞特七网络科技有限公司 Outfit7 Network Technology 商标的域名、社交媒体帐户或相关网址；</li>
          <li>
            利用应用程序或相关资产和/或奥飞特七网络科技有限公司 Outfit7 Network Technology 的商标进行以下活动或从事与以下内容相关的活动
            (i) 鼓吹欺诈、非法侵入、暴力、歧视、不良主题、非法活动或色情内容；(ii) 对奥飞特七网络科技有限公司 Outfit7 Network
            Technology 和/或其产品、员工及代理进行不真实、不诚实的诋毁或诽谤；和/或 (iii) 包含其他负面内容；
          </li>
          <li>转售、复制、传输、分发、显示、翻译或修改本应用程序或制作本应用程序或其任何部分的衍生品；</li>
          <li>再利用、复制或分发由本应用程序提供的文本、图片、音乐、条码、视频、数据、超链接、展示或任何其他内容；</li>
          <li>再分发、转授权、租借、发行、表演、出售、分配、租赁、营销、转让，或将应用程序提供给第三方；</li>
          <li>通过禁用、逆向工程、反汇编或其他方式，企图提取应用程序或其任何部分的源代码，适用法律或约束应用程序所涉及的任何开源组件的使用许可条款明确允许或要求的除外；</li>
          <li>删除、掩盖或以任何方式篡改应用程序中出现的任何警告、通告（包括但不限于任何版权或其他所有权通告）或链接；</li>
          <li>在操作汽车时使用本应用程序；</li>
          <li>删除或篡改应用程序及其相关资产中所包含的奥飞特七网络科技有限公司 Outfit7 Network Technology 的商标、商业标识或法律通告；</li>
          <li>以任何方式利用服务试图访问未经授权的任何服务、数据、帐户或网络；</li>
          <li>发布任何含有裸露、暴力或攻击性主题的信息，或任何含访问此类内容的链接；</li>
          <li>企图骚扰、滥用、威胁、诽谤，或试图侵犯或违背任何其他第三方权利；</li>
          <li>以任何非法、不诚实或欺骗性的方式使用应用程序；</li>
          <li>
            未经奥飞特七网络科技有限公司 Outfit7 Network Technology 授权，使用技术或任何其他手段访问奥飞特七网络科技有限公司
            Outfit7 Network Technology 的专有信息；
          </li>
          <li>使用或启用任何自动化系统访问奥飞特七网络科技有限公司 Outfit7 Network Technology 的网站或计算机系统；</li>
          <li>企图引入病毒或其他任何恶意计算机代码，中断、破坏或限制任何计算机软件、硬件或通讯设备的功能；</li>
          <li>企图越权访问奥飞特七网络科技有限公司 Outfit7 Network Technology 的计算机网络或用户帐户；</li>
          <li>怂恿可能构成刑事犯罪或引起民事责任的行为；或</li>
          <li>以本 EULA 中未明确允许的方式使用应用程序。</li>
        </ul>

        <p>
          奥飞特七网络科技有限公司 Outfit7 Network Technology 保留其自由裁量权，判定何种行为违反本 EULA 的条款。此外，如果您违反本 EULA 的条款，奥飞特七网络科技有限公司 Outfit7 Network Technology 保留采取适当措施的权利，包括但不限于完全或部分禁止您使用奥飞特七网络科技有限公司 Outfit7 Network Technology 的应用程序。
        </p>

        <h2>1.3 应用程序许可的用途</h2>
        <p>
          使用应用程序及与应用程序有关的您的任何信息均受应用程序功能及上述有限许可的限制。未经奥飞特七网络科技有限公司
          OUTFIT7 NETWORK TECHNOLOGY 事先以书面形式明确授权，不得使用应用程序或应用程序的任何部分。
        </p>

        <h2>2. 通过 APPLE APP STORE 访问和下载应用程序</h2>
        <p>
          以下内容适用于通过 Apple App Store 访问或从 Apple App Store 下载任何应用程序（&ldquo;来自
          App Store 的应用程序&rdquo;）的行为：
        </p>
        <ul>
          <li>
            您确认并同意（1）本 EULA 仅为您与奥飞特七网络科技有限公司 Outfit7 Network Technology 之间达成的协议，并非您与
            Apple 达成的协议；（2）奥飞特七网络科技有限公司 Outfit7 Network Technology，而非 Apple，独立对来自 App Store
            的应用程序及其内容负责。使用来自 App Store 的应用程序时，请务必遵守《Apple App Store 服务条款》。
          </li>

          <li>
            请仅（1）在运行 iOS 系统（ Apple 的专属操作系统软件）的 Apple 品牌产品上，并（2）按照《Apple App Store 服务条款》中&ldquo;使用规则&rdquo;的规定使用来自
            App Store 的应用程序。
          </li>

          <li>您确认 Apple 没有义务就&ldquo;来自 App Store 的应用程序&rdquo;提供任何维护与支持服务。</li>

          <li>
            若来自 App Store 的应用程序未遵守任何适用保证条款，您可以通知 Apple，其会就该来自 App Store 的应用程序为您按购买价格办理退款，且退款金额为适用法律允许的最高金额，此外，Apple
            对来自 App Store 的应用程序不承担任何其他担保义务。在奥飞特七网络科技有限公司 Outfit7 Network Technology 和 Apple
            之间，因不遵守任何保证条款而导致的任何其他索赔、损失、责任、损害、成本或费用均由奥飞特七网络科技有限公司 Outfit7 Network Technology
            独立承担。
          </li>

          <li>
            您和奥飞特七网络科技有限公司 Outfit7 Network Technology 确认，在奥飞特七网络科技有限公司 Outfit7
            Network Technology 和 Apple 之间，在您拥有和使用来自 App Store 的应用程序的过程中，由您或任意第三方就来自 App
            Store 的应用程序提出的任何索赔，Apple 均不承担责任，这些索赔包括但不限于（1）产品责任索赔；（2）来自 App Store 的应用程序未达到适用法律或法规要求而产生的索赔；（3）根据消费者保护法或类似法规而产生的索赔。
          </li>

          <li>
            您和奥飞特七网络科技有限公司 Outfit7 Network Technology 确认，如有任何第三方称来自 App Store 的应用程序或您对来自
            App Store 的应用程序的拥有和使用侵犯了该第三方的知识产权，则在奥飞特七网络科技有限公司 Outfit7 Network Technology 和
            Apple 之间，由奥飞特七网络科技有限公司 Outfit7 Network Technology，而非 Apple，独自依据本 EULA 的要求来调查、辩护、解决和处理该知识产权侵权索赔。
          </li>

          <li>
            您和奥飞特七网络科技有限公司 Outfit7 Network Technology 确认并同意，Apple 及其子公司为本 EULA 的第三方受益者，您接受本
            EULA 后，Apple 即有权（并被视为接受该权利）执行本 EULA。
          </li>

          <li>使用来自 App Store 的应用程序，即表明您声明并保证（1）您所在国家或地区不在美国政府禁运令范围内，或未被美国政府指定为&ldquo;支持恐怖主义&rdquo;的国家或地区；（2）您不在美国政府的任何违禁方或受限方名单上。</li>

          <li>在不限制本 EULA 任何其他条款的情况下，使用来自 App Store 的应用程序时，您必须遵守所有适用第三方协议。</li>
        </ul>

        <h2>3. 通过任何 ANDROID 应用程序商店访问和下载本应用程序</h2>
        <p>以下内容适用于通过任何 Android 应用程序商店下载任何应用程序（&ldquo;来自 Android 商店的应用程序&rdquo;）的行为：</p>

        <p>
          您确认并同意（1）本 EULA 仅为您与奥飞特七网络科技有限公司 Outfit7 Network
          Technology 之间达成的协议，并非您与任何 Android 商店达成的协议；（2）奥飞特七网络科技有限公司 Outfit7 Network
          Technology，而非任何 Android 商店，独立对来自 Android 商店的应用程序及其内容负责。使用来自 Android 商店的应用程序时，请务必遵守特定
          Android 商店提供的条款和条件。
        </p>

        <p>
          请仅（1）在运行 Android 操作系统软件的设备上；并按照（2）Android 商店提供的条款和条件的规定使用来自
          Android 商店的应用程序。
        </p>
        <p>.</p>
        <p>在不限制本 EULA 任何其他条款的情况下，使用来自 Android 商店的应用程序时，您必须遵守所有适用第三方协议。</p>
        <h2>3. 用户内容</h2>
        <p>尽管与本 EULA 所述相左，在服从许可限制及此处定义的用户内容具体规定的前提下，仍允许您以未修改的形式（完全由应用程序所产生）创建并使用&ldquo;用户内容&rdquo;（定义如下）。</p>
        <p>
          修改由应用程序创建的&ldquo;用户内容&rdquo;或将该&ldquo;用户内容&rdquo;纳入其他文件均需获得奥飞特七网络科技有限公司 Outfit7
          Network Technology 的明确书面批准。禁止转授权、租借、租赁、转让或以其他形式分发应用程序或应用程序的使用权。本 EULA 并不授权您接收，亦不责成奥飞特七网络科技有限公司
          Outfit7 Network Technology 提供硬拷贝文档、支持、电话援助、或本应用程序的优化或更新。
        </p>
        <h2>3.1 用户内容的提交</h2>
        <p>
          应用程序可能允许您创建视频、数据、照片、消息、图表、文本和其他信息（&ldquo;用户内容&rdquo;）等内容，并将这些用户内容分享至奥飞特七网络科技有限公司
          Outfit7 Network Technology 或您可能指定的其他站点，包括社交网站。您对您在应用程序上或通过应用程序上传、发布、展示、链接至或以其他方式提供（以下简称&ldquo;分享&rdquo;）的用户内容负全部责任，您同意奥飞特七网络科技有限公司
          Outfit7 Network Technology 仅作为您在线分发和发布用户内容的被动渠道。
        </p>
        <p>
          本应用程序可以用两种方式分享用户内容：(1) 一对一（例如，通过短信、电子邮件）；(2) 一对多（例如电子邮件）。除非明确说明，否则奥飞特七网络科技有限公司
          Outfit7 Network Technology 不会存储用户内容，而且奥飞特七网络科技有限公司 Outfit7 Network Technology 只作为一个被动渠道，为您提供一个创建用户内容的平台，并（仅仅是为了改善用户体验而为您提供帮助）为您引入第三方服务（例如，电子邮件客户端），您可以决定哪些用户内容可以分享，以及如何、何时和与谁分享。
        </p>

        <p>您了解除非明确声明，否则奥飞特七网络科技有限公司 Outfit7 Network Technology 不会对访问您的用户内容、分享您的用户内容、您的用户内容隐私或保密信息等行为加以控制，而您连同第三方服务提供商，受制于上述第三方服务提供商的服务条款，对此类用户内容完全管控。</p>

        <p>
          您同意不分享以下用户内容：(i) 可能给您、任何其他人或任何动物造成伤害、损失、身体或精神伤害、情绪困扰、死亡、残疾、缺陷，生理或心理疾病的风险；(ii)
          可能给任何人或财产造成任何其他损失或损害的风险；(iii）企图通过让儿童接触不适当的内容，获得其详细身份信息或以其他方式伤害或利用儿童；(iv) 可能构成或促成犯罪或侵权行为；(v)
          涉及奥飞特七网络科技有限公司 Outfit7 Network Technology 认为是非法的、有害的、辱骂性的、种族攻击性的、诽谤的、侵害性的、侵犯个人隐私权和公开权、骚扰、羞辱他人（公开或其他方式）、恶意中伤、威胁、亵渎或其他令人反感的任何信息和内容；(vi)
          涉及任何非法信息或内容（包括但不限于披露证券法规定的内幕消息或另一方的商业机密）；(vii) 涉及您无权依托任何法律或合约或信托关系而提供的任何信息或内容；或者
          (viii) 包含您确定不正确及非当前的任何信息或内容。
        </p>

        <p>如果您认为通过本应用程序分享但存储在第三方服务的某些用户内容侵犯了您的权利，请联系相应的第三方服务提供商，即此用户内容之存储地。</p>

        <p>您同意您所分享的任何用户内容未违反、也不会违反任何形式的第三方权利，包括但不限于任何&ldquo;知识产权&rdquo;（定义见第7节）或公开权和隐私权。若您的用户内容中包含音乐，您在此声明您是全部版权权利的所有者，包括但不限于此类用户内容涉及的每一个音乐作品（包括歌词）及录音中相关的表演、机械和录音权，并且您有权进行以下许可授权。</p>

        <p>
          尽管与此处所述相左，奥飞特七网络科技有限公司 Outfit7 Network Technology 保留其自由裁量权，即在不预先通知的情况下，有权以任何理由或无需任何理由，审阅、监控、禁止、编辑、删除或不提供任何用户内容。达成本
          EULA 后，您在此不可撤销地同意此类监控，且您确认并同意对您用户内容的分享不享有隐私权。若奥飞特七网络科技有限公司 Outfit7 Network
          Technology 在任何时候自行决定监控用户内容，奥飞特七网络科技有限公司 Outfit7 Network Technology 对用户内容不承担任何责任，且/或没有任何义务删除或编辑不当用户内容。
        </p>

        <p>
          针对确认自己不满 14 岁的用户，禁止其创建和分享本节所述的用户内容。我们《隐私政策》中对收集未满 14 岁儿童的相关信息提供了进一步说明，请参阅
          <ExternalLink
            url="https://talkingtomandfriends.com/customs-privacy/o7-networktech-privacypolicy/zh-simp"
            text="https://talkingtomandfriends.com/customs-privacy/o7-networktech-privacypolicy/zh-simp"
          />（iOS
          设备）和 <ExternalLink
            url="https://talkingtomandfriends.com/customs-privacy/networktech-android-privacypolicy/zh-simp"
            text="https://talkingtomandfriends.com/customs-privacy/networktech-android-privacypolicy/zh-simp"
          />（Android
          设备）。
        </p>

        <h2>3.2 用户内容许可授权</h2>
        <p>
          通过奥飞特七网络科技有限公司 Outfit7 Network Technology 应用程序创建用户内容，您即明确授予，且声明并保证您有权授予奥飞特七网络科技有限公司
          Outfit7 Network Technology 买断式的、可转授权的、可转让的、永久性的、不可撤销的且非独家的全球许可，用于使用、复制、改编、发行、盈利、罗列相关信息、编辑、翻译、分发、公开表演、公开展示、制作衍生作品，或以其他方式将所有这类用户内容以及您的用户内容中所包含的您的姓名、用户名、声音和/或肖像用于与本应用程序和奥飞特七网络科技有限公司
          Outfit7 Network Technology（包括其继承者和分支机构）业务有关的用途，无论全部或部分，无论通过媒体或技术等任何形式，无论现在已知的或未来开发的，此用途包括但不限于促销及再分发本应用程序的一部分或全部（及由此而产生的任何衍生作品），或以任何媒体格式以及通过任何媒体系列涉及各类广告（如横幅广告、移动广告和视频广告）的任何奥飞特七网络科技有限公司
          Outfit7 Network Technology 应用程序。您还在此授予本应用程序的每一个用户一项非排他性许可，以通过应用程序访问您的用户内容，并且在本协议约束下，通过本应用程序的功能或互联网，使用、复制、分发、展示和表现此类用户内容。
        </p>
        <h2>3.3 用户内容的具体规定</h2>
        <p>
          通过奥飞特七网络科技有限公司 Outfit7 Network Technology 的应用程序创建用户内容，您即同意这些具体规定概述了您对任何奥飞特七网络科技有限公司
          Outfit7 Network Technology 知识产权的使用：
        </p>
        <ul>
          <li>
            用户内容将用于纯粹的非商业性展示、识别及讨论符合这些具体规定的奥飞特七网络科技有限公司 Outfit7 Network Technology 应用程序。禁止您基于奥飞特七网络科技有限公司
            Outfit7 Network Technology 的知识产权创建新的应用程序、产品和/或内容，即使这类内容免费。
          </li>

          <li>未经奥飞特七网络科技有限公司 Outfit7 Network Technology 的明确书面批准，不得向客户或您的用户内容的访客收取任何形式的费用。</li>

          <li>
            奥飞特七网络科技有限公司 Outfit7 Network Technology 保留通过奥飞特七网络科技有限公司 Outfit7 Network
            Technology 的应用程序进行盈利或使用用户内容或其中一部分的自由裁量权。若奥飞特七网络科技有限公司 Outfit7 Network
            Technology 未行使其通过用户内容盈利的权利，您可通过广告来使用自己的用户内容进行盈利，但您独立承担确保这些广告符合任何及全部适用的法律、规定、法规及开发者政策的责任。
          </li>

          <li>
            您不得创建或使用让人误以为奥飞特七网络科技有限公司 Outfit7 Network Technology 为创建者或发起人的用户内容。此外，禁止您根据奥飞特七网络科技有限公司
            Outfit7 Network Technology 的知识产权创建与奥飞特七网络科技有限公司 Outfit7 Network Technology 的标志、商标或奥飞特七网络科技有限公司
            Outfit7 Network Technology 知识产权的任何其他元素相类似的任何素材。未经奥飞特七网络科技有限公司 Outfit7 Network
            Technology 事先明确书面批准，您不得以任何方式修改奥飞特七网络科技有限公司 Outfit7 Network Technology 的知识产权。
          </li>

          <li>
            未经奥飞特七网络科技有限公司 Outfit7 Network Technology 的明确书面批准，不得制造或分发（免费或其他形式）与任何奥飞特七网络科技有限公司
            Outfit7 Network Technology 知识产权有关的任何物品。
          </li>
        </ul>
        <p>
          奥飞特七网络科技有限公司 Outfit7 Network Technology 保留其自由裁量权，判定哪些用户内容符合上述具体规定，并可随时以任何理由撤消本
          EULA 中的有限许可。
        </p>
        <h2>3.4 用户内容责任范围</h2>
        <p>
          奥飞特七网络科技有限公司 Outfit7 Network Technology 不对您或任何其他用户或第三方利用本应用程序创建或通过本应用程序分享的任何用户内容负责，也不对此承担任何责任。您对自己的用户内容以及对其分享或发布所产生的后果承担全部责任。您理解并同意，因使用您发送、上传、下载、传播、张贴、传播、展示、分享或以其他方式提供的任何用户内容，或通过您使用本应用程序所进行的访问而造成的任何损失或损害，您将承担全部责任。针对您的用户内容以及本
          EULA 中包含的任何其他声明和保证，您确认、声明并保证：
        </p>
        <ol>
          <li>您已获得用户内容中每一位可认明自然人的书面同意，根据本应用程序及本 EULA 的规定使用这些人的姓名或肖像，且他们不追究您的此类使用可能引起的责任。</li>
          <li>根据本 EULA 和应用程序的规定，您的用户内容及奥飞特七网络科技有限公司 Outfit7 Network Technology 对其使用不会侵犯任何第三方的权利，包括但不限于任何知识产权、隐私权和公开权。</li>
          <li>奥飞特七网络科技有限公司 Outfit7 Network Technology 可以依照本协议行使对您的用户内容的权利，且没有责任按照任何劳资协议或其他方式向您的用户内容支付任何会费、余款、酬金、费用或特许版权费用。</li>
          <li>奥飞特七网络科技有限公司 Outfit7 Network Technology 不对公开展示或滥用您的用户内容的行为负责。您理解并知晓您接触到的用户内容可能是不准确的、冒犯性的、不得体的、令人反感的或不适合儿童的，并且您同意，对于您宣称的由于此类用户内容而招致的任何损害，奥飞特七网络科技有限公司 Outfit7 Network Technology 不承担责任。</li>
        </ol>
        <h2>4. 访问</h2>
        <p>
          要访问和使用应用程序，您需要自行提供所需设备、网络连接或移动设备和/或服务计划。奥飞特七网络科技有限公司
          Outfit7 Network Technology 不保证在所有地区提供该应用程序。您确认，使用应用程序时，您的无线运营商可能会收取数据、消息和/或其他无线接入费用。请向您的运营商核实任何此类费用对您是否适用。通过移动设备和/或
          PC 设备访问应用程序所产生的任何费用均由您独立承担。享有本应用程序的使用权的前提是在使用过程中遵守您与第三方的任何协议的适用条款。
        </p>
        <h2>5. 应用内购买</h2>
        <p>尽管我们有些应用程序提供免费下载，但我们所有应用程序都可能提供应用内购买，包括订阅（&ldquo;应用内购买&rdquo;）。尽管我们的免费下载应用程序提供应用内购买，但您无需使用真实货币进行任何应用内购买，也能打开和访问所述应用程序的所有级别以及进阶的所有必要功能。</p>

        <p>
          进行应用内购买时，可能只需输入您的应用商店密码，因此请您自行负责保护密码安全。您的身份验证和安全维护受制于应用商店的具体条款和您移动设备的操作系统（&ldquo;OS&rdquo;）。**
          下载应用程序后，请注意 iOS 和安卓系统分别有 15 分钟和 30 分钟的时限，在该时限内进行应用内购买无需输入应用商店密码。还请注意，安卓手机的 OS
          2.1 或更早版本不要求输入应用商店账户密码即可进行应用内购买。**
        </p>

        <p>
          完成应用内购买后，您将获得有限的个人不可转让、不可转授权、可撤销的许可，允许您在我们应用程序中使用虚拟货币、虚拟道具和其他虚拟内容（&ldquo;虚拟道具&rdquo;）。虚拟道具不可赎回，且不得使用现金或其他有价物进行应用程序外交易。无论事先通知与否，奥飞特七网络科技有限公司
          Outfit7 Network Technology 均可随时管理、规范、控制、修改或淘汰虚拟道具和虚拟道具的价格。奥飞特七网络科技有限公司 Outfit7
          Network Technology 行使上述权利时，不对您或任何第三方承担任何责任。
        </p>

        <p>
          注册订阅即表示您同意自动续订，且除非您取消订阅，否则即表明您授权我们从您的账户扣除续订费用。除出售时另有说明外，自动续订期限和续订费用与您的初始订阅期限及初始订阅价格相同。您可以在您应用商店的账户设置中管理订阅。
          <strong>请注意，从设备上删除应用程序有时并不表示您取消订阅。</strong>关于如何管理应用内购买的更多讯息，请通过
          <ExternalLink
            url="mailto:support@outfit7networktech.com"
            text="support@outfit7networktech.com"
          />，联系我们。
        </p>

        <p><strong>应用内购买的价格从 0.89 欧元到 99.99 欧元不等</strong>。所有费率以欧元计价，价格可能随时调整，且须折算为当地货币，这些将由您下载应用的 App store 完成，这意味着价格可能上下浮动 15%。您应当经常在应用程序中查看您需要购买的虚拟道具在您所在地区的实际价格。</p>

        <p>
          应用内购买受您所在移动平台（例如，iOS 平台、安卓平台、Windows 平台等）的支付条款和条件的限制。奥飞特七网络科技有限公司
          Outfit7 Network Technology 不对支付流程进行管理或控制。进行应用内购买前，请查看适用平台提供商的相关条款和条件。
        </p>

        <p>所有购买均为最终购买。您在此确认，奥飞特七网络科技有限公司 OUTFIT7 NETWORK TECHNOLOGY 没有义务因任何理由提供退款，且除非适用法律要求。</p>
        <h2>6. 期限和终止</h2>
        <p>
          本 EULA 的条款自您安装或使用本应用程序之日起生效，至您放弃使用本应用程序或奥飞特七网络科技有限公司
          Outfit7 Network Technology 终止本 EULA 之日效力终止，以日期先到者为准。您可以通过卸载本应用程序终止此 EULA 。
        </p>

        <p>
          若卸载本应用程序，您为本应用程序所支付的款项将不予退回。奥飞特七网络科技有限公司 Outfit7 Network
          Technology 保留对终止本 EULA 或出于任何原因（包括但不限于奥飞特七网络科技有限公司 Outfit7 Network Technology 有合理理由认为您违反了本
          EULA）要求您从设备上删除应用程序的自由裁量权。本 EULA 终止后，您必须立即停止使用应用程序，并销毁您拥有或控制的该应用程序的全部副本。
        </p>

        <p>
          终止并不会限制奥飞特七网络科技有限公司 Outfit7 Network Technology 的任何其他权利或法律或股权补救措施。如果任何平台依据与您之间的协议，在您的设备上禁止使用本应用程序，任何与奥飞特七网络科技有限公司
          Outfit7 Network Technology 相关的许可权也会随之终止。
        </p>

        <h2>7. 保留权利</h2>
        <p>
          您已获得本应用程序的许可，且您的权利受制于本 EULA。除非在此明确为您授予许可，奥飞特七网络科技有限公司
          Outfit7 Network Technology 保留应用程序中所有的权利、所有权与利益。本许可仅限于奥飞特七网络科技有限公司 Outfit7
          Network Technology 的知识产权，并不包括其他专利或知识产权的任何权利。
        </p>

        <p>
          无论是否注册而与所有应用程序有关，奥飞特七网络科技有限公司 Outfit7 Network Technology 保留与奥飞特七网络科技有限公司
          Outfit7 Network Technology 知识产权（定义见下文）相关的所有权利、所有权和利益，第三方技术的版权除外。奥飞特七网络科技有限公司
          Outfit7 Network Technology 软件受全世界适用法律和条约的保护。
        </p>

        <p>鉴于本 EULA，&ldquo;知识产权&rdquo;是指依照任何州、国家、地区或其他司法管辖区的法律，所有专利权、商业名称、专有权、版权权利、所有权、计算机代码、视听效果、主题、角色、角色名称、情节、对话、场景、美术作品、音效、音乐作品、复制作品权、道德权、公开权、商标、商业外观和服务标志权、声誉、商业机密权，现在可能存在或此后出现的其他知识产权，以及与此相关的所有应用程序及其注册、续展和扩展。</p>
        <h2>8. 第三方内容</h2>
        <h2>8.1 第三方技术</h2>
        <p>我们某一或所有应用程序会使用第三方技术，而使用这些第三方技术可能需要商业许可（&ldquo;商用第三方技术&rdquo;）或开源软件许可（&ldquo;开源组件&rdquo;）。</p>

        <p>以下列表仅包括许可中要求此类披露的商用第三方技术：</p>
        <ul>
          <li>
            <strong>NGUI</strong> :有些应用使用在 NGUI 站点许可下授权的 NGUI 库：<ExternalLink
              url="http://www.tasharen.com/?page_id=140"
              text="http://www.tasharen.com/?page_id=140"
            />。
          </li>
          <li>
            <strong>Unity</strong> ：我们使用 Unity 软件开发某些应用程序，使用本应用程序即表示您同意遵守
            Unity 服务条款，条款内容请参阅  <ExternalLink
              url="https://unity3d.com/legal/terms-of-service"
              text="https://unity3d.com/legal/terms-of-service"
            />。
          </li>
        </ul>
        <p>
          如开源组件许可条款与本 EULA 存在冲突，则以该许可条款替代本 EULA。如适用于开源组件的许可条款禁止本
          EULA 中与该开源组件有关的任何限制条款，则该限制条款不适用于该开源组件。
        </p>
        <h2>8.2 第三方内容和功能</h2>
        <p>
          通过本应用程序提供的某些内容和功能可能涉及来自第三方的素材。奥飞特七网络科技有限公司 Outfit7
          Network Technology 可能会整合第三方的内容，及提供到第三方网站的链接，为您提供便利。您同意奥飞特七网络科技有限公司 Outfit7
          Network Technology 不负责内容的检测或评估，亦不对内容的准确性负责，且奥飞特七网络科技有限公司 Outfit7 Network
          Technology 不对任何第三方材料或网站或第三方的其他材料、产品或服务提供担保，且不对第三方材料或网站或第三方的其他材料、产品或服务承担任何责任。您同意您在使用任何第三方材料时不会侵犯或违反任何其他方的权利，且奥飞特七网络科技有限公司
          Outfit7 Network Technology 对您使用该材料不承担任何责任。
        </p>

        <p>奥飞特七网络科技有限公司 Outfit7 Network Technology 的一款或所有应用程序均使用以下第三方内容，而以下列表仅包含那些需要依据其许可进行披露的第三方内容：</p>
        <ul>
          <li>
            <strong>ADMIRA</strong>：本应用程序使用<ExternalLink
              url="http://www.admira.sg/collection"
              text="http://www.admira.sg/collection"
            /> 所提供的图像。所有版权属于 Admira Pte LTD。图像为非卖品。
          </li>
        </ul>
        <h2>8.3 第三方广告投放技术</h2>
        <p>
          每款应用程序均有可能包含第三方动态游戏内广告投放技术，该技术可在您上线时将广告临时上传至您的移动设备和/或个人电脑上的应用程序中并更换这些广告。在您使用本应用程序的过程中，我们或运行该广告投放技术的第三方可能会收集和使用《隐私政策》中指定的信息。奥飞特七网络科技有限公司
          Outfit7 Network Technology 已与第三方广告提供商（具体见《隐私政策》）达成合作，每一款应用程序均有可能同时使用其中一家或多家广告提供商。
        </p>
        <h2>9. 免责声明</h2>
        <p>
          在适用法律允许的最大范围内，本应用程序按&ldquo;原样&rdquo;提供，连同所有缺陷，不提供任何形式的保证或任何类型的性能保证或保障，您的使用风险完全自担。能否获得满意的质量与性能，其风险完全取决于您。奥飞特七网络科技有限公司
          OUTFIT7 NETWORK TECHNOLOGY 及奥飞特七网络科技有限公司 OUTFIT7 NETWORK TECHNOLOGY 的授权商（在本节与第
          11 节可统称为&ldquo;奥飞特七网络科技有限公司 OUTFIT7 NETWORK TECHNOLOGY&rdquo;）并不做出，并在此否认所有明示、暗示或法定担保，包括因交易、使用或贸易过程引起的暗示担保条件、不间断使用、适销性、质量满意度、特定用途适合性、不侵犯第三方权利及担保（若有）。
        </p>
        <p>奥飞特七网络科技有限公司 OUTFIT7 NETWORK TECHNOLOGY 概不保证：不干扰您享用本应用程序；本应用程序会满足您的要求；本应用程序的运行不间断或无差错；本应用程序能与任何其他应用程序交互或兼容；本应用程序中的任何缺陷都会得到修正；或本应用程序可以在相同或多个设备上重新安装使用。</p>
        <p>奥飞特七网络科技有限公司 OUTFIT7 NETWORK TECHNOLOGY 或任何授权代表或第三方不会对所提供的口头或书面建议进行担保。某些司法管辖区对消费者适用法定权利的暗示担保或限制不允许除外或限制，因此上述某些或全部除外和限制可能并不适用于您。</p>
        <h2>10. 责任范围</h2>
        <p>
          在适用法律允许的最大范围内，任何情况下对于由此 EULA 或本应用程序引发或与之相关的行动所招致的对您的任何人身伤害、财产损失、利润损失、替换产品或服务的成本、数据丢失、声誉损失、停工、计算机失效或故障，或其他任何形式的直接的或间接的、特殊的、偶然的、继发的或惩罚性损害，奥飞特七网络科技有限公司
          OUTFIT7 NETWORK TECHNOLOGY、其子公司或分支机构及授权商都不承担责任，无论是否由侵权行为（包括疏忽）、合同、严格责任或其他引起，也无论
          OUTFIT7 是否已被告知这种损害的可能性，亦无论是否修复、更换或退款（经我们独家确认后），因为本应用程序不会完全赔偿您的任何损失。
        </p>

        <p>
          某些司法管辖区不允许就死亡、人身伤害、虚假陈述、或某些蓄意或无意的行为、或违反特定法规、或偶发或继发损害的限制进行责任限制，因此上述某些或全部责任限制可能并不适用于您。任何情况下，奥飞特七网络科技有限公司
          Outfit7 Network Technology 对您的所有损失所承担的责任均不超过您向本应用程序支付的实际金额（适用法律要求的情况除外）。本限制适用于，但不限于与本应用程序、服务或通过本应用程序提供的内容有关的任何事项。您同意本
          EULA 中限制责任的条款是本 EULA 的基本条款。
        </p>
        <h2>11. 赔偿</h2>
        <p>
          您同意维护、赔偿，并不追究奥飞特七网络科技有限公司 Outfit7 Network Technology 及其员工、承包商、管理人员及董事因您使用或误用本应用程序、违反
          EULA 或违反第三方的任何权利所导致的任何及全部索赔、诉讼、损害、费用、法律诉讼、罚款、处罚、债务、开销（包括律师费）。对于因您的任何其他问题所产生的赔偿，奥飞特七网络科技有限公司
          Outfit7 Network Technology 保留专属抗辩及控制的权利，在这种情况下，您将配合坚持任何可用抗辩。
        </p>
        <h2>12. 强制补救</h2>
        <p>
          您同意，违反本 EULA 会对奥飞特七网络科技有限公司 Outfit7 Network Technology 造成不可挽回的伤害，而赔偿金并不足以充当适当的补救，除了依此可能获得的任何补救，或法律上未约定、无其他保证或无损害赔偿证据，奥飞特七网络科技有限公司
          Outfit7 Network Technology 有权寻求衡平补救。
        </p>
        <h2>13. 适用法律</h2>
        <p>如果您在欧盟地区使用本应用程序，本协议及因本协议引发或与本协议有关的所有纠纷或争议均受英格兰和威尔士法律管辖，并根据英格兰和威尔士法律进行解释，与法律冲突的规定除外；如果您在任何其他国家或地区使用本应用程序，则本协议及因本协议引发或与本协议有关的所有纠纷或争议均受加利福尼亚州法律管辖，并根据加利福尼亚州法律进行解释，与法律冲突的规定除外。</p>
        <h2>14. 仲裁</h2>
        <p>
          我们将做出一切合理努力，以解决您与奥飞特七网络科技有限公司 Outfit7 Network
          Technology 之间的任何分歧。若努力失败，使用本应用程序即表示您同意您因本协议和/或本应用程序，就本协议和/或本应用程序，或以任何形式与本协议和/或本应用程序相关，而与奥飞特七网络科技有限公司
          Outfit7 Network Technology 存在的任何索赔、纠纷或争议均应通过具有约束力的最终仲裁解决，详情请参阅第 15 节。
        </p>

        <p>
          如果您是美利坚合众国以外任何国家的居民：(a) 任何此类仲裁应由国际商会（&ldquo;ICC&rdquo;）管制，并依照 ICC &ldquo;仲裁规则&rdquo;（&ldquo;ICC
          仲裁规则&rdquo;）先于单一仲裁员执行；(b) 仲裁应在英国伦敦或您与奥飞特七网络科技有限公司 Outfit7 Network Technology 双方商定的地点举行；(c)
          仲裁员应遵循加利福尼亚州法律与 ICC 仲裁规则及适用法规的限制，并应考虑法律上公认的特权索赔；(d) 所有仲裁申请、沟通和诉讼程序应使用英语做出或执行；并且
          (e) 如果该仲裁条款的任何部分被视为无效、不可执行或非法，或与 ICC 仲裁规则相冲突，则该仲裁条款的余项应保持效力，且根据其条款进行解释，就像这里没有无效的、不可执行的、非法的或冲突条款。
        </p>
        <h2>15. 最终条款</h2>
        <h2>15.1 资格</h2>
        <p>使用本应用程序的任何人均向奥飞特七网络科技有限公司 Outfit7 Network Technology 确认自己已达到适用法律的成年人年龄，或是脱离父母独立生活的未成年人，或已得到父母或监护人的合法许可，完全有能力就此协议中阐明的条款、条件、义务、主张、陈述和保证进行交涉，并遵守和履行此协议的规定。</p>
        <h2>15.2 可分割性与残存</h2>
        <p>
          如果此 EULA 的任何条款非法，或根据适用法律而无法履行，则该条款的其余部分需进行修订，以尽可能实现原始条款的效力，同时本
          EULA 的所有其他条款仍具备充分效力。
        </p>        <h2>15.3 完整协议</h2>
        <p>
          这些条款就本应用程序构成您与奥飞特七网络科技有限公司 Outfit7 Network Technology 之间的完整协议，并取代关于此类主题的所有之前或同时期的理解。除奥飞特七网络科技有限公司
          Outfit7 Network Technology 书面签署外，对本 EULA 的修订或修改不具备约束力。对于任何一方，未行使及延迟行使依此的任何权利或任何权力，皆不妨碍依此进一步行使任何其他权利。如果本
          EULA、任何适用的购买或其他条款之间有冲突，请以本 EULA 的条款为准。
        </p>
        <h2>15.4 语言</h2>
        <p>本 EULA 原始版本使用英文撰写。将本 EULA 翻译为任何其他语言均以满足当地需求为目的，如英文版本与非英文版本间存在任何争议、矛盾或差异，在您所在辖区当地法律不禁止的情况下，均应以英文版本为准。</p>
        <h2>15.5 出口</h2>
        <p>
          您同意遵守塞浦路斯、美国、欧盟及其他适用的出口管制法律，如未事先获得并遵守必要的政府授权，您同意不将本应用程序转让给此类法律禁止的外国国民或外国地点。根据适用法律规定，您保证您自己并非奥飞特七网络科技有限公司
          Outfit7 Network Technology 禁止与之开展业务的对象。
        </p>
        <h2>15.6 改变</h2>
        <p>
          我们将适时地对本 EULA 进行必要的更新，以保护我们的用户、提供最新信息、并反映法律和技术的变更。本
          EULA 的最新版将约束您对我们应用程序的使用，并提供在线查阅 <ExternalLink
            url="https://talkingtomandfriends.com/customs-privacy/eula-o7-networktech/zh-simp"
            text="https://talkingtomandfriends.com/customs-privacy/eula-o7-networktech/zh-simp"
          />。请定期检查此网页的任何变更。我们保留随时在上述地址发布修订后的
          EULA 版本以修改本 EULA 的权利。您使用经变更的本应用程序，即表示您接受这些变更。
        </p>
        <h2>15.7 联系信息</h2>
        <p>
          如果您有针对本应用程序的任何问题、投诉或要求需要联系奥飞特七网络科技有Outfit7 Network Technology电子邮件至 <ExternalLink
            url="mailto:support@outfit7networktech.com"
            text="support@outfit7networktech.com"
          /> 或发送电子邮件至 <ExternalLink
            url="mailto:support@outfit7.com"
            text="support@outfit7.com"
          />。
        </p>
        <h2>15.8 应用程序销售权转让</h2>
        <p>
          在适用法律允许的范围内，并且交易当事方同意将销售权进行转让时，您同意将本应用程序的销售所有权（通过电子下载方式销售的软件）转让给奥飞特七网络科技有限公司
          Outfit7 Network Technology 所在地。
        </p>
        <p>Outfit7 Network Technology Co., Ltd  本协议最后更新时间为 2023 年 3月29日</p>
      </div>
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'
import ExternalLink from "@components/ExternalLink.vue";

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    ExternalLink,
    Header,
  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: justify;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
